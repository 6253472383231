import React from 'react';
import Radium from 'radium';
import { GreenButton } from '../../components';

let style = {
  root: {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    width: '100%',
    backgroundColor: '#FFF',
  },
  logo: {
    height: 40,
    width: 230,
    backgroundImage: 'url(https://cloudfront.startupmedia.com/greenback/logos/gb-logo-icon-dark.png)',
    backgroundSize: 'cover',
    marginLeft: 40,
    display: 'block',
    '@media (max-width: 640px)': {
      marginLeft: 20,
      width: 39,
      backgroundImage: 'url(https://cloudfront.startupmedia.com/greenback/icons/gb-icon-transparent.png)',
    }
  },
  button: {
    marginRight: 40,
    '@media (max-width: 640px)': {
      marginRight: 20,
    }
  }
}

const Header = (props) => {

  return (
    <div style={style.root}>
      <div style={style.logo} />
      <div style={style.button}>
        <GreenButton
          outlined="true"
          label="Log In"
          href="//www.gogreenback.com"
          small="true"
        />
      </div>
    </div>
  )
}


export default Radium(Header);
