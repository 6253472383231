import React from 'react';
import Radium from 'radium';

let style = {
  root: {

  },
  questionWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#F5F5F5',
    }
  },
  question: {
    fontFamily: "ProximaNova-SemiBold",
    fontSize: 22,
    letterSpacing: 1,
  },
  action: {
    fontFamily: "ProximaNova-SemiBold",
    fontSize: 22,
  },
  answer: {
    position: 'relative',
    paddingLeft: 25,
    lineHeight: 1.4,
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 0.2s ease-out',
  }
}


const Item = ({props}) => {
  return (
    <div style={style.root}>
      <div style={style.questionWrapper}>
        <div style={style.question}>
          How do I change my password?
        </div>
        <div style={style.action}>
          +
        </div>
      </div>
      <div style={style.answer}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet massa tincidunt nunc pulvinar sapien et. Eget egestas purus viverra accumsan. Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Vel eros donec ac odio. Luctus venenatis lectus magna fringilla urna. Euismod in pellentesque massa placerat duis ultricies. Ac tortor dignissim convallis aenean. Vel risus commodo viverra maecenas accumsan lacus. Quis varius quam quisque id diam vel. Proin libero nunc consequat interdum varius. Nisi vitae suscipit tellus mauris.
      </div>
    </div>
  )
}

export default Radium(Item);
