import React from 'react';
import {
  Header,
  Footer,
  Wave,
  Menu,
  Card
} from './../';
import {Link} from 'react-router-dom';


class View extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, navType, waveType, center} = this.props;

    let style = {
      root: {
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      gradient: {
        position: 'absolute',
        top: 80,
        left: 0,
        right: 0,
        height: 100,
        backgroundImage: 'linear-gradient(180deg, #FCFAFA 0%, #FFFFFF 42%)',
        zIndex: 0,
      },
      content: {
        position: 'relative',
        flex: 1,
        maxWidth: 760,
        width: '90%',
        marginTop: 40,
        marginBottom: 240,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1,
      },
      children: {
        marginTop: 40,
      }
    }

    if (center) {
      style.content.alignItems = 'center';
    }

    return (
      <div>
        <div style={style.root}>
          <Header/>
          <div style={style.gradient} />
          <div style={style.content} className="content">
            <Menu />
              {children}
          </div>
          <Wave type={waveType}/>
          <Footer/>
        </div>
      </div>
    )
  }
}


export default View;
