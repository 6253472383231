import React, { Component } from 'react';
import _ from 'lodash';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import {
  View,
  Accordion
} from './../components';
import { getFAQ } from './../services';

class Managers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: []
    }
  }

  componentDidMount() {
    Promise.all([
      getFAQ({
        "filter":{
          "where": {"key": "faq_category_2"}
        }
      })
    ]).then(res => {
      console.log('response',res)
      this.setState({
        questions: res[0][0].body_json,
      });
    }).catch(err => {
      console.log('error',err)
      this.setState({
        questions: []
      });
    });
  }

  render() {

    return (
      <View navType='login' waveType='mellow'>
        <Accordion marginTop={60} questions={this.state.questions}/>
      </View>
    );
  }
}

export default Managers;
