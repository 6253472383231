import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch, Link } from 'react-router-dom';
import {
  General,
  Managers,
  Boosters,
  Members,
  MobileApp
} from './routes';

class App extends Component {
  render() {

    return (
      <Router>
        <Switch>
          <Route exact path="/" component={General} />
          <Route path="/managers" component={Managers} />
          <Route path="/boosters" component={Boosters} />
          <Route path="/members" component={Members} />
          <Route path="/mobile-app" component={MobileApp} />
        </Switch>
      </Router>
    );
  }
}

export default App;
