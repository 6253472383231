import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import { GreenButton, Card } from '../../components';

let style = {
  root: {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 660,
    width: '100%',
    backgroundColor: '#FFF',
  },
  header: {
    fontSize: 32,
    letterSpacing: 2,
    fontFamily: "'ProximaNova-Regular'",
    textAlign: 'center',
    lineHeight: 1.7,
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 40,
  },
  item: {
    width: '22%',
    marginBottom: 20,
  }
}

const Menu = (props) => {

  const location = window.location.hash.split('/')[1]

  return (
    <div root={style.root}>
      <div style={style.header}>Frequently Asked Questions</div>
      <div style={style.menu}>
        <GreenButton
          style={style.item}
          label="General"
          component={props => <Link to="/" {...props}/>}
          outlined={location === '' ? false : true}
          small={'true'}
        />
        <GreenButton
          style={style.item}
          label="Managers"
          component={props => <Link to="/managers" {...props}/>}
          outlined={location === 'managers' ? false : true}
          small={'true'}
        />
        <GreenButton
          style={style.item}
          label="Boosters"
          small={'true'}
          outlined={location === 'boosters' ? false : true}
          component={props => <Link to="/boosters" {...props}/>}
        />
        <GreenButton
          style={style.item}
          label="Members"
          small={'true'}
          outlined={location === 'members' ? false : true}
          component={props => <Link to="/members" {...props}/>}
        />
      </div>
    </div>
  )
}


export default Radium(Menu);
