import React from 'react';
import Radium from 'radium';

let style = {
  root: {
    position: 'absolute',
    bottom: -10,
    left: 0,
    right: 0,
    zIndex: 0,
    '@media (max-width: 640px)': {
      display: 'none'
    }
  },
  svg: {
    display: 'block',
  }
}



const Wave = (props) => {

  const { type } = props;

  return (
    <div style={style.root}>
      {type === 'wind' ?
        <svg style={style.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 351"><defs><linearGradient x1="100%" y1="40.3011%" x2="0%" y2="59.6989%" id="a"><stop stopColor="#90C3CE" offset="0%"/><stop stopColor="#CFEDEC" offset="100%"/></linearGradient></defs><path d="M0 186.0083c175 3.4273 322-9.503 441-38.7907C619.5 103.286 771.5 18.227 1024 0v451H0V186.0083z" fill="url(#a)" fillRule="evenodd"/></svg>
        : type === 'mellow' ?
        <svg style={style.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1057 226"><defs><linearGradient x1="100%" y1="45.2706%" x2="0%" y2="54.7294%" id="a"><stop stopColor="#90C3CE" offset="0%"/><stop stopColor="#CFEDEC" offset="100%"/></linearGradient></defs><path d="M0 103.9698c114.6224-22.7355 250.9766-22.7355 409.0629 0 237.1293 34.1031 367.5272-47.125 647.9371 0V412H0V103.9698z" transform="translate(0 -86)" fill="url(#a)" fillRule="evenodd"/></svg>
        : ''
      }
    </div>
  )
}


export default Radium(Wave);
