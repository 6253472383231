import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

let style = {
  card: {
    position: 'relative',
    width: '100%',
    background: '#FFF',
    boxShadow: `0 0 8px 0 rgba(0,0,0,0.20)`,
    borderRadius: 20,
  },
  content: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  }
}

const Card = ({children, ...props}) => {
  return (
    <div style={{...style.card, ...props.root}} {...props}>
      <div style={{...style.content, ...props.content}} >
        {children}
      </div>
    </div>
  )
}

Card.propTypes = {
  style: PropTypes.object //Modifies the content style
}

export default Radium(Card);
