import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import './style.css';

function expandIcon({ isActive }) {
  return (
    <div className="expandIcon">{isActive ? '⌵' : '<'}</div>
  );
}

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: []
    }
  }

  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  }

  render() {
    const activeKey = this.state.activeKey;
    const { questions } = this.props;
    return (
      <Collapse
        accordion={true}
        onChange={this.onChange}
        activeKey={activeKey}
        className="collapse"
        expandIcon={expandIcon}
        style={{marginTop: this.props.marginTop}}
      >
        {
          questions.map((q,i) => {
            return (
              <Panel header={q.question} headerClass="header" className="panel">
                <div className="answer" dangerouslySetInnerHTML={ {__html: q.answer} }></div>
              </Panel>
            )
          })
        }
      </Collapse>
    );
  }
}

export default Accordion;
