import React from 'react';
import {
  Header,
  Footer,
  Wave,
  Menu,
  Card
} from './../';
import {Link} from 'react-router-dom';


class AppView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, navType, waveType, center} = this.props;

    let style = {
      root: {
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      title: {
        fontFamily: 'ProximaNova-SemiBold',
        fontSize: 26,
        textAlign: 'Center',
        lineHeight: '140%',
      },
      content: {
        position: 'relative',
        flex: 1,
        maxWidth: 760,
        width: '90%',
        marginTop: 40,
        marginBottom: 240,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1,
      },
      children: {
        marginTop: 40,
      }
    }

    if (center) {
      style.content.alignItems = 'center';
    }

    return (
      <div>
        <div style={style.root}>
          <div style={style.content} className="content">
            <div style={style.title}>
              Frequently Asked Questions
            </div>
              {children}
          </div>
          <Wave type={waveType}/>
          <Footer/>
        </div>
      </div>
    )
  }
}


export default AppView;
